import React, { Component } from 'react'
import { RightSquareOutlined } from '@ant-design/icons';


export class Icon extends Component {
	render() {
		const { type, className, style  } = this.props;
		return (
			<>{type!== undefined ? React.createElement(type, { className: className, style: style }): <RightSquareOutlined style={style}/>}</>
		)
	}
}

export default Icon
